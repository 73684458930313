import App from './App.vue';

import Vue from 'vue';

import { router } from './router';
import { store } from './store/index';

import { EventsPlugin } from '@jumpcloud/ui-events-vue';
import { TestIdMixin } from '@jumpcloud-ap/test-id';
import { addRootClasses } from '@jumpcloud/ui-components';
import { buildUiEventsOptions } from '@jumpcloud-ap/ui-events-setup';
import { initializeFlags } from './feature-flags';
import { useCoreUserInfo } from '@jumpcloud-ap/core-user-info';
import VueRouter from 'vue-router';

import '@jumpcloud-ap/time-based-trial/css';

Vue.use(VueRouter);
Vue.mixin(TestIdMixin);

Vue.use(EventsPlugin, buildUiEventsOptions());

let app: Vue;

export const destroy = () => {
  if (app) {
    app.$destroy();
  }
};

export const initialize = async () => {
  if (app) {
    destroy();
  }

  addRootClasses();

  const coreInfo = useCoreUserInfo();

  await (async () => {
    const settings = await fetch('/ui-settings.json').then(r => r.json());

    await initializeFlags({
      disableStreaming: settings.launchDarklyDisableStreaming,

      identity: {
        id: coreInfo.isAnMsp.value
          ? coreInfo.providerId.value ?? coreInfo.orgId.value!
          : coreInfo.orgId.value!,

        name: coreInfo.name.value ?? '',
      },

      launchDarklyConfig: {
        baseUrls: settings.launchDarklyBaseUrls,
        clientSideId: settings.launchDarklyClientSideId,
      },

      source: settings.launchDarklySource === 'localStorage'
        ? 'localStorage'
        : 'launchDarkly',
    });
  })();
  const container = document.getElementById('single-spa-application:@jumpcloud-ap/sidenav_app')!;
  const root = document.createElement('div');
  container.appendChild(root);

  [app] = new Vue({
    el: root,
    name: 'SideNavContainer',
    render(h) { return h(App); },
    router,
    store,
  }).$children;

  coreInfo.cleanup();
};
