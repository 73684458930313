// this module is a candidate for moving into shared library
// was modified, just to fit the needs of the sidenav app
import PartnersApi from '@/api/PartnersApi';
import Vue from 'vue';

export const partnerNames = {
  crowdStrike: 'crowdstrike',
};

export const statuses = {
  pending: 'PENDING_TRIAL',
  inTrial: 'IN_TRIAL',
  purchased: 'PURCHASED',
  suspended: 'SUSPENDED',
  cancelled: 'CANCELLED',
  terminated: 'TERMINATED',
};

const initialState = () => ({
  error: false,
  partners: [],
});

export default {
  namespaced: true,

  state: initialState(),

  actions: {
    async fetchPartners({ commit }) {
      try {
        const resp = await PartnersApi.fetch();
        commit('SET_PARTNERS', resp.results);
        commit('SET_ERROR', false);
      } catch (e) {
        commit('SET_ERROR', true);
      }
    },

  },

  mutations: {
    SET_PARTNERS(state, partners) {
      Vue.set(state, 'partners', partners);
    },

    SET_ERROR(state, error) {
      Vue.set(state, 'error', error);
    },

  },

  getters: {
    hasActiveCrowdStrikeLink(state) {
      const crowdStrikeLink = state.partners.find(
        (partner) => {
          const partnerIsCrowdStrike = partner.name.toLowerCase() === partnerNames.crowdStrike;

          const inValidStatus = [
            statuses.inTrial,
            statuses.purchased,
            statuses.suspended,
          ].includes(partner.status);

          // Race condition for new orgs:
          // After the account is verified, admin is redirected to Discover Page
          // There might be a little gap where partners service takes more time
          // to handle account verification, admin could still be on pending status
          // and see the wrong Discover Page
          // This check will allow new admins to see the CS Discover page
          // in case partners service is still handling transition from pending to in_trial

          // UPDATE: removed the check for first session as it is not needed for the sidenav app
          // the first-session check happens in the NavPanel component
          const inPending = partner.status === statuses.pending;

          return partnerIsCrowdStrike && (inValidStatus || inPending);
        },
      );

      return !!crowdStrikeLink;
    },
  },
};
