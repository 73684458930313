<template>
  <Nudge
    :class="$style.popover"
    :placement="placement"
    :shouldCloseOnBlur="false"
    :showNudge="isVisible"
    :showOverlay="false"
    :targetSelector="target"
    :title="headerText"
    @close="handleHide"
  >
    <template #body>
      <img
        :alt="altText"
        :class="$style.diagram"
        :src="image"
      >

      <MarkdownBlock
        :class="$style.text"
        :mdTemplate="bodyText"
      />
    </template>

    <template #footer>
      <ProductTourPagination />
    </template>
  </Nudge>
</template>

<script>
import {
  MarkdownBlock,
  Nudge,
} from '@jumpcloud/ui-components';
import { UiEvents } from '@jumpcloud/ui-events-vue';
import { mapActions, mapState } from 'vuex';
import ProductTourPagination from '@/components/ProductTour/ProductTourPagination.vue';
import ProductTourSteps from '@/components/ProductTour/ProductTourSteps';

export default {
  name: 'ProductTourPopover',

  ProductTourSteps,
  hideLabel: 'Hide',

  components: {
    MarkdownBlock,
    Nudge,
    ProductTourPagination,
  },

  props: {
    altText: {
      type: String,
      default: '',
    },
    bodyText: {
      type: String,
      default: '',
    },
    headerText: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'right-start',
    },
    target: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    ...mapState('ProductTourModule', [
      'productTourStepIndex',
    ]),
  },

  mounted() {
    this.delayShow();
  },

  methods: {
    ...mapActions('ProductTourModule', [
      'setShowProductTour',
    ]),

    // delay placing the nudge until the left nav has fully animated collapse
    delayShow() {
      setTimeout(() => {
        this.isVisible = true;
      }, 250);
    },

    handleHide() {
      UiEvents.triggerButtonClicked({
        description: 'product tour hide button clicked',
        page: this.$page,
        section: 'header',
        region: 'Product Tour Popover',
        value: `step: ${this.productTourStepIndex}`,
        text: 'x',
      });

      this.setShowProductTour(false);
    },
  },
};
</script>

<style module scoped>

.diagram {
  margin-bottom: var(--jc-spacer-large);
  width: 100%;
}

.text {
  font-size: 14px;
}

@media (max-width: 768px) {
  [class^="ModalContentStructure__modalContent"] {
    grid-template-columns: 100%;
  }

  .popover {
    --nudge-width: 325px;
  }

  .popover >>> [class^="Nudge__footer"] {
    gap: 0;
  }
}
</style>
