<template>
  <ModalStructure
    :class="$style.modal"
    :data-test-id="$testId('producttourmodal')"
    footerAlignment="right"
    :shouldCloseOnBlur="false"
    :showModal="showModal"
    @close="handleHide"
  >
    <template v-slot:header>
      <div :class="$style.header">
        <HeadingSmall
          :hasMargin="false"
        >
          {{ overviewStep.headerText }}
        </HeadingSmall>

        <ButtonClose
          :data-test-id="$testId('hideButton')"
          @click="handleHide"
        />
      </div>
    </template>

    <template v-slot:body>
      <img
        :alt="overviewStep.imgAltText"
        :class="$style.diagram"
        :src="overviewStep.image"
      >

      <MarkdownBlock
        :class="$style.text"
        :mdTemplate="overviewStep.bodyText"
      />
    </template>

    <template v-slot:footer>
      <ProductTourPagination />
    </template>
  </ModalStructure>
</template>

<script>
import {
  ButtonClose, HeadingSmall, MarkdownBlock, ModalStructure,
} from '@jumpcloud/ui-components';
import { UiEvents } from '@jumpcloud/ui-events-vue';
import { eventBus } from '@jumpcloud-ap/event-bus';
import { mapState } from 'vuex';
import ProductTourPagination from './ProductTourPagination.vue';
import ProductTourSteps, { stepIndexes } from './ProductTourSteps';

export default {
  name: 'ProductTourModal',

  ProductTourSteps,
  hideLabel: 'Hide',

  components: {
    ButtonClose,
    HeadingSmall,
    MarkdownBlock,
    ModalStructure,
    ProductTourPagination,
  },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    ...mapState('ProductTourModule', [
      'productTourStepIndex',
    ]),

    overviewStep() {
      return this.$options.ProductTourSteps[stepIndexes.modal];
    },
  },

  created() {
    eventBus.subscribe('product-tour-modal:open', this.handleOpen);
    eventBus.subscribe('product-tour-modal:close', this.handleHide);
  },

  beforeDestroy() {
    eventBus.unsubscribe('product-tour-modal:open', this.handleOpen);
    eventBus.unsubscribe('product-tour-modal:close', this.handleHide);
  },

  methods: {
    handleHide() {
      UiEvents.triggerButtonClicked({
        description: 'product tour hide button clicked',
        page: this.$page,
        section: 'header',
        region: 'Product Tour Popover',
        text: 'Hide',
        value: `step: ${this.productTourStepIndex}`,
      });

      this.showModal = false;
    },
    handleOpen() {
      this.showModal = true;
    },
  },
};
</script>

<style module scoped>
  .container {
    border: solid 1px var(--jcPrimary);
    border-radius: inherit;
    padding: var(--jc-spacer-medium);
    width: 400px;
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--jc-spacer-large);
  }

  .diagram {
    margin-bottom: var(--jc-spacer-large);
    width: 100%;
  }

  .text {
    font-size: 14px;
  }

  .footer {
    display: block;
    margin-top: var(--jc-spacer-large);
  }
</style>
