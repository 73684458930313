<template>
  <NavPanelLink
    :active="isActive"
    :activeLink="activeLink"
    :item="item"
    :panelExpanded="panelExpanded"
  >
    <span
      v-if="showGetStartedSticker && !isCustomer"
      v-show="panelExpanded"
      :class="$style.badge"
    >
      get started
    </span>
  </NavPanelLink>
</template>

<script>
import { DateUtil } from '@jumpcloud-ap/date-util';
import { mapGetters } from 'vuex';
import NavPanelLink from '@/components/NavPanelLink.vue';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';

export default {
  name: 'DiscoverLink',

  components: {
    NavPanelLink,
  },

  props: {
    activeLink: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: NavPanelLinkListItem,
      required: true,
    },
    orgCreatedDate: {
      type: String,
      default: '',
    },
    panelExpanded: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters('CoreUserInfoModule', ['isCustomer']),

    showGetStartedSticker() {
      return !DateUtil.moreThanDaysBeforeToday(this.orgCreatedDate, 30);
    },
  },
};
</script>

<style scoped module>
.badge {
  background: var(--jcWarning100);
  border-radius: var(--jc-border-radius);
  color: var(--jcGrey900);
  flex: 0 0 auto;
  font-size: var(--jc-size-0p5);
  font-weight: bold;
  height: var(--jcBody);
  padding: 2px 4px;
  position: absolute;
  right: var(--jc-size-0p5);
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .badge {
    opacity: 0;
  }
}
</style>
