<template>
  <div>
    <NavPanelLink
      v-if="shouldShowChat"
      :active="isChatOpen"
      :class="{ [$style.navExpanded]: expanded }"
      data-nudge-selector="chatNavPanelLink"
      isButton
      :item="chatItem"
      :panelExpanded="expanded"
      @click="openChatApp"
    >
      <template v-slot:default>
        <DaysRemainingBadge
          v-if="inLegacyFreeTrial"
          :class="$style.remainingBadge"
          :comparisonDate="orgCreatedDate"
          :expanded="expanded"
          :trialLength="trialLength"
        />
        <div
          v-show="hasNewMessage"
          :class="[$style.newMessage, { [$style.newMessageExpanded]: expanded }]"
          title="new message"
        />
      </template>
    </NavPanelLink>
    <IntercomChatContentBlockerModal
      :shouldShowModal.sync="shouldShowContentBlockerModal"
    />
  </div>
</template>

<script>
import { DateUtil } from '@jumpcloud-ap/date-util';
import { LoadingIcon } from '@jumpcloud/ui-components';
import {
  intercomSetCloseCallback,
  intercomSetMessageUpdateCallback,
  intercomSetShowCallback,
  intercomShow,
  isIntercomLoaded,
} from '@jumpcloud-ap/intercom';
import { mapActions, mapGetters } from 'vuex';
import { planModuleName } from '@jumpcloud-ap/plans';
import DaysRemainingBadge from '@/components/DaysRemainingBadge.vue';
import IntercomChatContentBlockerModal from '@/components/IntercomChatContentBlockerModal.vue';
import NavPanelLink from '@/components/NavPanelLink.vue';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';
import SettingsApi from '@/api/SettingsApi';
import iconChat from '@/img/icons/nav/icon-nav-chat.svg';

export default {
  name: 'IntercomChat',

  components: {
    DaysRemainingBadge,
    IntercomChatContentBlockerModal,
    NavPanelLink,
  },

  props: {
    expanded: Boolean,

    imageComponent: {
      type: Object,
      default() {
        return iconChat;
      },
    },

    orgCreatedDate: {
      type: String,
      default: '',
    },
  },

  data() {
    return /* @alphabetize */ {
      hasNewMessage: false,
      isChatLoading: true,
      isChatOpen: false, // If we implement intercom elsewhere, move to store
      shouldShowChat: false,
      shouldShowContentBlockerModal: false,
      supportLevel: '',
      trialLength: 10,
    };
  },

  computed: {
    ...mapGetters('TimeBasedTrialModule', ['getIsInTrial', 'trialStart', 'trialEnd']),
    ...mapGetters('CoreUserInfoModule', ['isCustomer', 'isPrivilegedAccessAdmin', 'orgId']),

    ...mapGetters(planModuleName, ['planHasFeature']),

    chatItem() {
      const title = this.hasPremiumSupport ? 'Live Chat' : 'Chat';

      return new NavPanelLinkListItem({
        imageComponent: this.isChatLoading ? LoadingIcon : this.imageComponent,
        title,
      });
    },

    daysRemaining() {
      return this.trialLength - DateUtil.daysRemaining('today', this.orgCreatedDate);
    },

    hasPremiumSupport() {
      const hasSupportLevel = this.supportLevel && this.supportLevel.toLowerCase() === 'premium';
      const isInTimeBasedTrial = this.getIsInTrial
        && DateUtil.isInDateRange(this.trialStart, this.trialEnd);
      const entitled = this.planHasFeature('premiumSupport')
        && (isInTimeBasedTrial || this.inLegacyFreeTrial || this.isCustomer);

      return entitled || hasSupportLevel;
    },

    inLegacyFreeTrial() {
      return !this.isCustomer
        && DateUtil.withinDayRangeOfToday(this.orgCreatedDate, 10)
        && !this.getIsInTrial;
    },
  },

  created() {
    this.shouldShowChat = !!this.orgId && !!this.orgCreatedDate && !this.isPrivilegedAccessAdmin;
    this.fetchSettings();
  },

  async mounted() {
    if (this.shouldShowChat) {
      await this.fetchEntitledFeatureCodes();
      this.chatSetup();
    }
  },

  methods: {
    ...mapActions('OnboardingModule', ['updateChatSeen']),

    ...mapActions(planModuleName, ['fetchEntitledFeatureCodes']),

    chatSetup() {
      intercomSetCloseCallback(() => {
        this.updateChatSeen(true);
        this.isChatOpen = false;
      });

      intercomSetMessageUpdateCallback((unreadMessageCount) => {
        if (unreadMessageCount > 0) {
          this.updateHasNewMessage();
        }
      });

      intercomSetShowCallback(() => {
        this.isChatOpen = true;
        this.updateChatSeen(true);
      });

      if (isIntercomLoaded()) {
        this.isChatLoading = false;
      } else {
        setTimeout(() => {
          this.isChatLoading = false;
        }, 10000);
      }
    },

    async fetchSettings() {
      try {
        const { SUPPORT_LEVEL } = await SettingsApi.getGlobalSettings();
        this.supportLevel = SUPPORT_LEVEL;
      } catch (e) {
        // no-op when settings are not available
      }
    },

    openChatApp() {
      if (!isIntercomLoaded()) {
        this.updateShowError();
        return;
      }

      this.hasNewMessage = false;
      intercomShow();
    },

    updateHasNewMessage() {
      this.hasNewMessage = true;
    },

    updateShowError() {
      if (!this.isChatOpen) {
        this.shouldShowContentBlockerModal = true;
      }
    },
  },
};
</script>

<style module>
@value newMessageSize: 4px;

.newMessage {
  background-color: #e54852;
  border-radius: newMessageSize;
  height: newMessageSize;
  left: 32px;
  position: absolute;
  top: 8px;
  transition: top 0.4s linear;
  width: newMessageSize;
}

.remainingBadge {
  opacity: 0;
  position: absolute;
  right: var(--navPanelPadding);
  top: 2px;
  transition: all 200ms ease-in-out;
}

.navExpanded .remainingBadge {
  opacity: 1;
}
</style>
