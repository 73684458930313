import { eventBus } from '@jumpcloud-ap/event-bus';
import ProductTourSteps from '@/components/ProductTour/ProductTourSteps';
import Vue from 'vue';

// --------------------------------------------------------------------------
// Private functions
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Actions
// --------------------------------------------------------------------------

function setProductTourStep({ commit }, productTourStepIndex) {
  commit('SET_PRODUCT_TOUR_STEP', productTourStepIndex);
}

function setShowProductTour({ commit }, showProductTour) {
  commit('SET_SHOW_PRODUCT_TOUR', showProductTour);
}

// --------------------------------------------------------------------------
// Getters
// --------------------------------------------------------------------------

function getProductTourActiveLink(state) {
  const index = state.productTourStepIndex ?? 0;

  return ProductTourSteps[index].navPanelLink;
}

// --------------------------------------------------------------------------
// Mutations
// --------------------------------------------------------------------------

/**
 * Sets the current step of the Product Tour
 * @param {Number} productTourStepIndex - current index of Product Tour step from ProductTourSteps
 */
// eslint-disable-next-line
function SET_PRODUCT_TOUR_STEP(state, productTourStepIndex) {
  Vue.set(state, 'productTourStepIndex', productTourStepIndex);
}
/**
 * Sets the visibility of the Product Tour
 * @param {Boolean} showProductTour - set visibility to true/false
 */
// eslint-disable-next-line
function SET_SHOW_PRODUCT_TOUR(state, showProductTour) {
  eventBus.publish('product-tour:is-popover-open', showProductTour);
  Vue.set(state, 'showProductTour', showProductTour);
}

// Export for unit tests
export const createProductTourModule = () => ({
  namespaced: true,
  state: {
    // Product Tour State
    showProductTour: false,
    productTourStepIndex: 0,
  },
  actions: {
    setProductTourStep,
    setShowProductTour,
  },
  getters: {
    getProductTourActiveLink,
  },
  mutations: {
    SET_PRODUCT_TOUR_STEP,
    SET_SHOW_PRODUCT_TOUR,
  },
});

export default createProductTourModule();
