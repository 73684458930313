import GrowthPtOverviewPng from '@/img/growth/pt-overview.png?url';
import GrowthPtStep1Png from '@/img/growth/pt-step1.png?url';
import GrowthPtStep2Png from '@/img/growth/pt-step2.png?url';
import GrowthPtStep3Png from '@/img/growth/pt-step3.png?url';
import GrowthPtStep4Png from '@/img/growth/pt-step4.png?url';
import GrowthPtStep5Png from '@/img/growth/pt-step5.png?url';
import GrowthPtStep6Png from '@/img/growth/pt-step6.png?url';
import NavPanelLinkList from '@/components/NavPanelLinkList';

export const stepIndexes = {
  modal: 0,
  first: 1,
};

const {
  devicesLink,
  directoryInsightsLink,
  discoverLink,
  mfaLink,
  settingsLink,
  ssoAppsLink,
  usersLink,
} = NavPanelLinkList;

function ProductTourStep(data = {}) {
  this.navPanelLink = data.navPanelLink || '';
  this.bodyText = data.bodyText || '';
  this.headerText = data.headerText || '';
  this.image = data.image || '';
  this.imgAltText = data.imgAltText || '';
  this.placement = data.placement || '';

  return this;
}

const ProductTourSteps = [
  new ProductTourStep({
    bodyText: `Jumpcloud is a cloud directory platform empowering IT admins to easily unify their organization's technologies.

      Let's see how JumpCloud swiftly onboards a new employee, Jane, to the organization.

    `,
    headerText: 'Overview',
    image: GrowthPtOverviewPng,
    imgAltText: 'JumpCloud Directory Platform overview diagram showing the mutual relationships between the pillars of Identity, Devices, and Resources, with Insights across all three. The pillars appear under the core capabilities of unifying, managing, and securing.',
    navPanelLink: discoverLink,
  }),
  new ProductTourStep({
    bodyText: `With JumpCloud, IT admins can easily provision identities for employees on day one.

     By assigning Jane to User Groups, she is automatically allowed access to the applications and security settings enforced for all group members.`,
    headerText: 'Directory Management',
    image: GrowthPtStep1Png,
    imgAltText: 'JumpCloud Directory Platform diagram focusing on Identity, which includes Directory Aggregation and Lifecycle Management.',
    navPanelLink: usersLink,
    placement: 'right-start',
  }),
  new ProductTourStep({
    bodyText: `Connect identities to devices and enforce security policies across any OS anywhere.

     Bind Jane's user identity to her MacBook allowing her to login using her secure JumpCloud password.`,
    headerText: 'Device Management',
    image: GrowthPtStep2Png,
    imgAltText: 'JumpCloud Directory Platform diagram focusing on Devices, which includes Mac, Windows, and Linux operating systems, and Servers.',
    navPanelLink: devicesLink,
    placement: 'right-start',
  }),
  new ProductTourStep({
    bodyText: `JC integrates with hundreds of SSO apps and secures network access to wifi or VPNs.

     Jane can securely access any of her work applications on day one while being connected to her company's VPN.`,
    headerText: 'Authenticate to Resources',
    image: GrowthPtStep3Png,
    imgAltText: 'JumpCloud Directory Platform diagram focusing on Resources, which includes Applications with SSO, SAML 2.0, JIT and SCIM provisioning, as well as Networks.',
    navPanelLink: ssoAppsLink,
    placement: 'right-start',
  }),
  new ProductTourStep({
    bodyText: `Get a birds-eye view of event logs across your organization, enabling you to take important actions, assisting with auditing and compliance needs.

      Help Jane regain access to her account after a lockout, through automatic notifications.`,
    headerText: 'Insights',
    image: GrowthPtStep4Png,
    imgAltText: 'JumpCloud Directory Platform diagram focusing on Insights, which includes both Directory and System Insights.',
    navPanelLink: directoryInsightsLink,
    placement: 'right-start',
  }),
  new ProductTourStep({
    bodyText: `Enforce MFA or conditional access policies on devices, apps, networks, and user groups.

     Block Jane's access from unauthorized networks.`,
    headerText: 'Security Management',
    image: GrowthPtStep5Png,
    imgAltText: 'JumpCloud Directory Platform diagram showing the diagram as being fully traversed and highlighting the core capabilities of unifying, managing, and securing.',
    navPanelLink: mfaLink,
    placement: 'right-start',
  }),
  new ProductTourStep({
    bodyText: `Personalize your JC organization by configuring your email templates, company logo, and password complexities.

      Utilize Live Chat for Premium Support, free 24/7 for your trial.`,
    headerText: 'Settings & Support',
    image: GrowthPtStep6Png,
    imgAltText: 'JumpCloud Directory Platform overview diagram showing the mutual relationships between the pillars of Identity, Devices, and Resources, with Insights across all three. The pillars appear under the core capabilities of unifying, managing, and securing.',
    navPanelLink: settingsLink,
    placement: 'right-end',
  }),
];

export default ProductTourSteps;
