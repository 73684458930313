<template>
  <ul class="list">
    <li
      v-for="(link, index) in links"
      :key="`breadcrumbLink-${index}`"
      class="item"
    >
      <Link
        :class="[
          'breadcrumb',
          { 'active': index === currentStep }
        ]"
        :to="toLink(link)"
        @click.native="handleClick(index)"
      />
    </li>
  </ul>
</template>

<script>
import { Link } from '@jumpcloud/ui-components';
import { removeHash } from '@/utils/removeHash';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';

export default {
  name: 'BreadcrumbLinks',

  components: {
    Link,
  },

  props: {
    currentStep: {
      type: Number,
      default: 1,
    },

    links: {
      type: Array,
      required: true,
      validate(links) {
        return links.every(link => link instanceof NavPanelLinkListItem);
      },
    },
  },

  data() {
    return {
      activeClass: 'active',
    };
  },

  methods: {
    handleClick(index) {
      this.$emit('click', index);
    },

    toLink({ href }) {
      return removeHash(href);
    },
  },
};
</script>

<style scoped>
.list {
  align-items: center;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  align-items: center;
  display: flex;
}

.breadcrumb.breadcrumb {
  background-color: var(--jc-color-neutral-text-subdued);
  border-radius: 100%;
  height: var(--jc-spacer-small);
  opacity: 0.4;
  padding: 0;
  width: var(--jc-spacer-small);
}

.breadcrumb.breadcrumb:hover {
  opacity: 0.6;
}

.active.active {
  background-color: var(--jcPrimary);
  opacity: 1;
}
</style>
